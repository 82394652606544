import React from 'react';
import './App.css';
import {
  Redirect,
  Switch,
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Home from './pages/Home';
import Cart from './pages/Cart';
import MealPlanMenu from './pages/MealPlanMenu';
import ExtraMenu from './pages/ExtraMenu';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';

const App = () => {
  return (
    <div className='container'>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/cart' component={Cart} />
          <Route exact path='/meal-plan' component={MealPlanMenu} />
          <Route exact path='/extra' component={ExtraMenu} />
          <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/cookie-policy' component={CookiePolicy} />
          <Redirect to='/' />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
