import React, { forwardRef } from 'react';
import './styles.css';

const Banner = forwardRef(({ handleScrollMealPlans }, ref) => (
  <main ref={ref}>
     <header>
      <div className='header-content'>
        <div className='content-main'>
          <h1 className='company-name'>Mokka Food</h1>
          <p className='company-info'>
            Fresh prepared 5days weekly meals for North East London (including part of Essex)
            <br/><span className='soon-available'>Soon available for all of the UK!</span>
          </p>
          <img className='header-mobile-img' src='/images/banner.png' alt='banner' />
          <button className='view-plans-button' onClick={handleScrollMealPlans}>
            View Meal Plans <i className='fas fa-long-arrow-alt-right'></i>
          </button>
        </div>
      </div>
      <img className='header-img' src='/images/banner.png' alt='banner' />
    </header>
  </main>
));

export default Banner;
