import React from 'react';
import './styles.css';

const DoubleItemInCartPopUp = ({ isDisplayed, mealPlanName, handleAddOneMore, handleGoToCart, closePopUp }) => {
    return (
    isDisplayed === true
    ?
    <div className='modal-are-you-sure'>
        <div className='modal-double-item-content modal-content-are-you-sure'>
            <button className='modal-close-button-post-code' onClick={closePopUp}>
                <i className="fas fa-times"></i>
            </button>
            <h1 className='double-item-h1 are-you-sure-h1'>Are you sure?</h1>
            <p className='double-item-p'>You've already added a {mealPlanName} to your cart. <br/> Would you like to add one more?</p>
            <div className='double-item-buttons-div are-you-sure-buttons-div'>
                <button className='double-item-yes-button are-you-sure-yes-button' onClick={handleAddOneMore}>
                    <i className="fa button-icon fa-trash-arrow-up"></i> Yes, add one more.
                </button>
                <button className='double-item-no-button are-you-sure-no-button' onClick={handleGoToCart}>
                    <i className="fa button-icon fa-hand"></i> No, just go to the cart.
                </button>
            </div>
        </div>
    </div>
    :
    null
    );
};

export default DoubleItemInCartPopUp;
