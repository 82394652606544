import { selectCartItemsCount } from '../../../../redux/cart/cart.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { forwardRef } from 'react';
import React from 'react';
import './styles.css';

const CartMenu = forwardRef(({ cartCount, list }, ref) => {

  function areThereItemsInCart() {
    if (cartCount != null) {
      return cartCount !== 0
    } else {
      return false
    }
  }

  return (
    areThereItemsInCart()
    ?
    <main className='menu-cart-main' ref={ref}>
      {
      list.filter((item) => (
        item.type === 'meal-plan'
      )).map((item) => (
        <div className='meal-plan-cart-item-div'>
          <img src={item.img} alt='meal-plan'></img>
          <div className='meal-plan-cart-item-info-div'>
            <h1>{item.name}</h1>
            <p>{item.info}</p>
          </div>
        </div>
      ))
      }
    </main>
    :
    null
  )
});

const mapStateToProps = createStructuredSelector({
  cartCount: selectCartItemsCount,
});

export default connect(mapStateToProps)(CartMenu);
