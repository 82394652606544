import React from 'react';
import { useState } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel.jsx';
import { menuItemsData } from '../../components/common/Menu/data';
import { mealPlanItemsData } from '../../components/common/MealPlans/data';
import { cartAddItem, cartRemoveItem } from '../../redux/cart/cart.action';
import { selectCartItems, selectCartItemsCount } from '../../redux/cart/cart.selector';
import DoubleItemInCartPopUp from '../../components/common/DoubleItemInCartPopUp'
import MenuItemPopUpDetails from '../../components/common/Menu/MenuItemPopUpDetails'
import ThankYouPopUp from '../../components/common/ThankYouPopUp';
import Footer from '../../components/common/Footer';
import Menu from '../../components/common/Menu';
import './styles.css';

const MealPlanMenu = forwardRef((props, mealPlanRef) => {
  const [itemDetailsPopUp, setItemDetailsPopUp] = useState(null)
  const [thankYouPopUp, setThankYouPopUp] = useState(null)
  const [doubleItemInCartPopUp, setDoubleItemInCartPopUp] = useState(false)

  const fallbackMealPlan = mealPlanItemsData[0]
  const history = useHistory();
  const uuid = require('uuid')

  function openItemDetails(id) {
    const item = menuItemsData.find((item) => item.id === id)
    setItemDetailsPopUp(item)
  }

  function closeItemDetails() {
    setItemDetailsPopUp(null)
  }

  function getMealPlan() {
    return props.location.state[0] != null ? props.location.state[0] : fallbackMealPlan
  }

  function getMealPlanCalories() {
    const minCalories = getMealPlan().minCalories
    const maxCalories = getMealPlan().maxCalories

    if (minCalories == maxCalories) {
      return minCalories + " Cal"
    } else {
      return minCalories + " - " + maxCalories + " Cal"
    }
  }

  function addMealPlanToCart(shouldBypassAlreadyAddedCheck) {
    Mixpanel.track('Button Tapped: Add To Cart', {
      'page': 'MealPlanMenu'
    })

    var mealPlanForCart = {
      ...getMealPlan()
    }

    if (!shouldBypassAlreadyAddedCheck) {
      for (var i = 0; i < props.cartList.length; i++) {
        if (props.cartList[i].name === mealPlanForCart.name) {
          Mixpanel.track('Operation: Display DoubleItemInCart PopUp', {
            'page': 'MealPlanMenu'
          })
          setDoubleItemInCartPopUp(true)
          return
        }
      }
    }

    mealPlanForCart.id = uuid.v1()
    props.cartAddItem(mealPlanForCart)

    for (let i = 0; i < menuItemsData.length; i++) {
      if (mealPlanForCart.mealIDs.includes(menuItemsData[i].id)) {
        var mealItemForCart = {
          ...menuItemsData[i]
        }
  
        mealItemForCart.id = uuid.v1()
        props.cartAddItem(mealItemForCart)
  
        resetMenuItemCustomization(menuItemsData[i])
      }
    }

    Mixpanel.track('Operation: Meal plan added to cart successfully.', {
      'page': 'MealPlanMenu'
    })

    setThankYouPopUp(getMealPlan())
  }

  function addOneMore() {
    setDoubleItemInCartPopUp(false)
    addMealPlanToCart(true)
  }

  function resetMenuItemCustomization(menuItem) {
    delete menuItem.selectedOption;
    delete menuItem.selectedImage;

    if (menuItem.calories != null && menuItem.options != null) {
      if (menuItem.options.calories[0] != null) {
        menuItem.calories = menuItem.options.calories[0]
      }
    }
  }

  return (
    <main>
        <div className='worked-on-div'>
          <p><i className="fa-solid fa-utensils"></i> We're working on the menu and more customisations and extras are coming soon!</p>
        </div>
        <div className='top-buttons-div'>
          <button className='back-button' onClick={() => history.push('/')}>
            <i className="fa fa-arrow-left"></i>Go Back
          </button>
          {/* <select>
            <option>{getMealPlan().name}</option>
          </select> */}
          <button className='meal-plan-cart-button' onClick={() => addMealPlanToCart(false)}>
            <i className="fa fa-cart-plus"></i>Add To Cart
          </button>
        </div>
        <div className='selected-meal-plan-details-div'>
          <p>Selected Meal Plan</p>
          <h1>{getMealPlan().name}</h1>
          <h4>{getMealPlanCalories()}</h4>
        </div>
        {/* <div className='in-dev-div'>
          <h2><i class="fa-solid fa-circle-exclamation"></i> This website is currently under development.</h2>
          <h2>The menu is being worked on and will be available very soon!</h2>
          <h3>Thank you for your patience!</h3>
        </div> */}
        <Menu openItemDetails={openItemDetails} mealPlanItem={getMealPlan()} list={menuItemsData}/>
        <MenuItemPopUpDetails closeItemDetails={closeItemDetails} item={itemDetailsPopUp} />
        <ThankYouPopUp 
        mealPlan={thankYouPopUp} 
        closePopUp={() => setThankYouPopUp(null)} 
        goToCart={() => history.push('/cart')} 
        orderExtra={() => history.push('/extra')} />
        <DoubleItemInCartPopUp
        isDisplayed={doubleItemInCartPopUp}
        mealPlanName={getMealPlan().name}
        handleAddOneMore={addOneMore}
        handleGoToCart={() => history.push('/cart')}
        closePopUp={() => setDoubleItemInCartPopUp(false)} />
        <Footer />
    </main>
  );
});

const mapStateToProps = createStructuredSelector({
  cartCount: selectCartItemsCount,
  cartList: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item) => dispatch(cartAddItem(item)),
  cartRemoveItem: (item) => dispatch(cartRemoveItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanMenu);
