import React from 'react';
// import { useState } from 'react';
import './styles.css';

const ThankYouPopUp = ({ mealPlan, closePopUp, goToCart }) => {
    // const [isOrderExtraMessageShown, setIsOrderExtraMessageShown] = useState(false);

    return (
    mealPlan != null
    ?
    <div className='modal-thank-you'>
        <div className='modal-content-thank-you'>
            <button className='modal-close-button-post-code' onClick={closePopUp}>
                <i className="fas fa-times"></i>
            </button>
            <h1>Thank you!</h1>
            <p>Your personalised <b>{mealPlan.name}</b> has been added to your cart!</p>
            <div className='thank-you-buttons-div'>
                <button className='thank-you-button-go-to-cart' onClick={goToCart}>
                    <i className="fas button-icon fa-shopping-cart"></i> Go To Cart
                </button>
                {/* <button className='thank-you-button-order-extra' disabled>
                    <i className="fas button-icon fa-jar"></i> Order Extra
                </button> */}
            </div>
            {/* <p className='extra-message'>Sorry! The 'Order Extra' page is unavailable at the moment!</p> */}
        </div>
    </div>
    :
    null
    );
};

export default ThankYouPopUp;
