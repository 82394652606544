import React from 'react';
import './styles.css';

const EmptyCart = () => {
  return (
    <div className='emptyCart'>
      <p>Your cart is empty <i className='fa-regular fa-frown'></i></p>
    </div>
  );
};

export default EmptyCart;
