import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { cartRemoveAllItems } from '../../redux/cart/cart.action';
import { selectCartItems, selectCartItemsCount, selectCartTotal } from '../../redux/cart/cart.selector';
import AreYouSurePopUp from '../../components/common/AreYouSurePopUp';
import CartMenu from '../../components/common/Menu/CartMenu';
import EmptyCart from '../../components/Cart/EmptyCart';
import Footer from '../../components/common/Footer';
import Payment from '../Payment'
import './styles.css';

const Cart = ({ cartCount, cartList, cartTotal, cartRemoveAllItems }) => {
  const [areYouSurePopUp, setAreYouSurePopUp] = useState(false);
  const history = useHistory();

  const CartListDescription = () => {
    if (cartList != null) {
      return (
        cartList.map((item) => (
          <h3 className={item.type === 'meal-plan' ? 'orders-total-description-plan' : 'orders-total-description-item'} key={item.id}>
            {item.name} 
            {
            item.type === 'meal-plan' 
            ? 
            <span className='order-description-item-price'>£{item.price}</span> 
            : 
            <SelectedOption item={item} />
            }
          </h3>
        ))
      )
    } else {
      return null
    }
  }

  const SelectedOption = ({ item }) => {
    if (item.type === 'menu-item' && item.options != null) {
      if (item.selectedOption == null) {
        return (
          <span> with {item.options.idsAndNames[1]}</span>
        )
      } else {
        return (
          <span> with {item.options.idsAndNames[item.selectedOption + 1]}</span>
        )
      }
    } else {
      return null
    }
  }

  function calculateDeliveryDate(addDay) {
    let date = new Date()
    
    if (date.getDay() <= 5) {
      date.setDate(date.getDate() + (7 - date.getDay() + addDay))
      return date.toDateString()
    } else {
      date.setDate(date.getDate() + (7 - date.getDay() + addDay) + 7)
      return date.toDateString()
    }
  }

  function calculateSecondDeliveryDate(addDay) {
    let date = new Date()
    
    if (date.getDay() <= 5) {
      date.setDate(date.getDate() + (7 - date.getDay() + addDay + 2))
      return date.toDateString()
    } else {
      date.setDate(date.getDate() + (7 - date.getDay() + addDay) + 7 + 2)
      return date.toDateString()
    }
  }

  function getDeliveryDateForBE() {
    return calculateDeliveryDate(0) + ' (evening) or ' + calculateDeliveryDate(1) + ' (morning)'
  }

  function getSecondDeliveryDateForBE() {
    return calculateSecondDeliveryDate(0) + ' (evening) or ' + calculateSecondDeliveryDate(1) + ' (morning)'
  }

  return (
    <>
      <div className='worked-on-div'>
        <p>
          <i className="fa-solid fa-code"></i> If you encounter any issues with your order, please contact 
          <span> </span>
          <a className='dev-email' href="mailto:developers@mokkafood.co.uk">developers@mokkafood.co.uk</a>!
        </p>
      </div>
      <div className='cart-header'>
        <button className='back-button' onClick={() => history.push('/')}>
          <i className="fa fa-arrow-left"></i>Go Back
        </button>
        {
          cartCount !== 0 ? (
            <button className='clear-cart-button' onClick={() => {setAreYouSurePopUp(true)}}>
              <i className="fa fa-trash"></i>Clear Cart
            </button>
          ) : ( null )
        }
      </div>
      {cartCount === 0 ? (
        <EmptyCart />
      ) : (
        <div className='orders'>
          <h1 className='orders-heading'>Your Orders</h1>
          <div className='orders-menu'>
            <CartMenu list={cartList} />
          </div>
          <h3 className='orders-total-description'> </h3>
          <CartListDescription />
          <h3 className='orders-total-description-plan'>Delivery £0.00</h3>
          <h3 className='orders-total'>
          <span className='order-description-your-total-label'>Your total </span>
          <span className='order-description-your-total-label'>(inc VAT):</span>
          <span className='order-description-item-price'>£{cartTotal}</span>
          </h3>
          <div>
            <h1 className='orders-heading'>Delivery Date</h1>
            <p className='orders-delivery-date'>First Delivery: {calculateDeliveryDate(0)} (evening) or {calculateDeliveryDate(1)} (morning)</p>
            <p className='orders-delivery-date-desc'>Meals for Monday and Tuesday</p>
            <p className='orders-delivery-date'>Second Delivery: {calculateSecondDeliveryDate(0)} (evening) or {calculateSecondDeliveryDate(1)} (morning)</p>
            <p className='orders-delivery-date-desc'>Meals for Wednesday, Thursday and Friday</p>
            <h1 className='orders-heading'>Customer Details</h1>
          </div>
          <Payment 
          cartList={cartList} 
          cartTotal={cartTotal} 
          deliveryDate={getDeliveryDateForBE()} 
          secondDeliveryDate={getSecondDeliveryDateForBE()}/>
          {/* <div className='in-dev-div'>
            <h2><i class="fa-solid fa-circle-exclamation"></i> This website is currently under development.</h2>
            <h2>We will be able to take orders very soon!</h2>
            <h3>Thank you for your patience!</h3>
          </div> */}
          <AreYouSurePopUp 
          areYouSure={areYouSurePopUp} 
          handleYes={cartRemoveAllItems} 
          handleNo={() => {setAreYouSurePopUp(false)}}/>
        </div>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  cartCount: selectCartItemsCount,
  cartList: selectCartItems,
  cartTotal: selectCartTotal,
});

const mapDispatchToProps = (dispatch) => ({
  cartRemoveAllItems: () => dispatch(cartRemoveAllItems())
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
