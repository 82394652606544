import React from 'react';
import './styles.css';

const AreYouSurePopUp = ({ areYouSure, handleYes, handleNo }) => {
    return (
    areYouSure === true
    ?
    <div className='modal-are-you-sure'>
        <div className='modal-content-are-you-sure'>
            <h1 className='are-you-sure-h1'>Are you sure?</h1>
            <p>You're about to clear everything in your cart!</p>
            <div className='are-you-sure-buttons-div'>
                <button className='are-you-sure-yes-button' onClick={handleYes}>
                    <i className="fa button-icon fa-trash-arrow-up"></i> Yes, clear it.
                </button>
                <button className='are-you-sure-no-button' onClick={handleNo}>
                    <i className="fa button-icon fa-hand"></i> No, stop!
                </button>
            </div>
        </div>
    </div>
    :
    null
    );
};

export default AreYouSurePopUp;
