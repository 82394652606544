import React from 'react';
import axios from 'axios';
import Geocode from 'react-geocode'
import LoadingPopUp from '../common/LoadingPopUp';
import PurchaseInfoPopUp from '../common/PurchaseInfoPopUp';
import { useStateIfMounted } from 'use-state-if-mounted';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel';
import { cartRemoveAllItems } from '../../redux/cart/cart.action';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import './styles.css';

<script src="https://js.stripe.com/v3/"></script>

// eslint-disable-next-line
const prodApi = 'https://mokkafoodapi.co.uk:8443'
// eslint-disable-next-line
const devApi = 'https://localhost:8443'

const CARD_OPTIONS = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#555',
            color: '#111',
            fontWeight: 900,
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#555' },
            '::placeholder': { color: '#555' }
        },
        invalid: {
            iconColor: '#f30',
            color: '#f30'
        }
    }
}

const PaymentForm = ({ customer, customerDetailsAreAdded, cartList, cartTotal, deliveryDate, secondDeliveryDate, cartRemoveAllItems }) => {
    const [success, setSuccess] = useStateIfMounted(false)
    const [isLoadingPopUpShown, setIsLoadingPopUpShown] = useStateIfMounted(false)
    const [isPurchaseInfoPopUpShown, setIsPurchaseInfoPopUpShown] = useStateIfMounted(false)
    const [serverMessage, setServerMessage] = useStateIfMounted('')

    Geocode.setApiKey('AIzaSyBd5yIaLFuCgaTrOyjqtE_pPm4xZW9bq00');
    Geocode.setRegion('UK');

    const stripe = useStripe()
    const elements = useElements()
    const history = useHistory();

    function getTotalPriceInCents() {
        const allPricesInCents = cartList.map((cartItem) =>
            cartItem.priceInCents != null ? cartItem.priceInCents : 0
        )

        var totalPriceInCents = 0

        for (var i = 0; i < allPricesInCents.length; i++) {
            totalPriceInCents += allPricesInCents[i]
        }

        return totalPriceInCents
    }

    function getRemodelledCartItems() {
        var remodelledItems = []

        cartList.map((cartItem) =>
            remodelledItems.push(
                getRemodelledCartItem(cartItem)
            )
        )

        return remodelledItems
    }

    function getRemodelledCartItem(item) {
        var remodelledOptions = [{
            id: 0,
            name: 'no-options'
        }]

        if (item.options != null) {
            remodelledOptions = []
            
            for (const [key, value] of Object.entries(item.options.idsAndNames)) {
                remodelledOptions.push(
                    {
                        id: key,
                        name: value
                    }
                )
            }
        }

        return {
            name: item.name,
            type: item.type,
            price: item.price == null ? 0.00 : item.price,
            options: remodelledOptions,
            selectedOption: item.selectedOption == null ? 0 : item.selectedOption + 1
        }
    }

    function checkPostCode() {
        return new Promise(resolve => {
            if (customer.postCode != null && customer.postCode.length >= 4) {
                Geocode.fromAddress(customer.postCode).then(
                    (response) => {
                        const isInLondon = (response.results[0].address_components.find((item) => item.short_name.includes('London')) != null)
                        const isInEssex = (response.results[0].address_components.find((item) => item.short_name === 'Essex') != null)

                        Mixpanel.track('Operation: Google Maps successfully found post code', {
                            'userInput': customer.postCode,
                            'page': 'Cart'
                        })

                        if (isInEssex) {
                            const isInEpping = (response.results[0].address_components.find((item) => item.short_name.includes('Epping')) != null)
                            const isInLoughton = (response.results[0].address_components.find((item) => item.short_name.includes('Loughton')) != null)
                            const isInWaltham = (response.results[0].address_components.find((item) => item.short_name.includes('Waltham Abbey')) != null)

                            if (isInEpping || isInLoughton || isInWaltham) {
                                Mixpanel.track('Operation: Post code is in Essex', {
                                    'userInput': customer.postCode,
                                    'page': 'Cart'
                                })
                                resolve({ postCodeError: null, isPostCodeSupported: true })
                            } else {
                                Mixpanel.track('Operation: Post code is in unsupported delivery region', {
                                    'userInput': customer.postCode,
                                    'page': 'Cart'
                                })
                                resolve({ postCodeError: 'Unfortunately, we don\'t deliver to post code ' + customer.postCode + ' yet. You could send us an e-mail to request delivery for your location at orders@mokkafood.co.uk!', isPostCodeSupported: false })
                            }
                        } else {
                            if (isInLondon && 
                                (customer.postCode[0].toUpperCase() === 'N' 
                                || customer.postCode[0].toUpperCase() === 'E'
                                || (customer.postCode[0].toUpperCase() === 'I' && customer.postCode[1].toUpperCase() === 'G')
                                || (customer.postCode[0].toUpperCase() === 'R' && customer.postCode[1].toUpperCase() === 'M'))) {
                                Mixpanel.track('Operation: Post code is in London', {
                                    'userInput': customer.postCode,
                                    'page': 'Cart'
                                })
                                resolve({ postCodeError: null, isPostCodeSupported: true })
                            } else {
                                Mixpanel.track('Operation: Post code is in unsupported delivery region', {
                                    'userInput': customer.postCode,
                                    'page': 'Cart'
                                })
                                resolve({ postCodeError: 'Unfortunately, we don\'t deliver to post code ' + customer.postCode + ' yet. You could send us an e-mail to request delivery for your location at orders@mokkafood.co.uk!', isPostCodeSupported: false })
                            }
                        }
                    },
                    (error) => {
                        var errorMessage = ''

                        if (error != null) {
                            if (error.toString() != null) {
                                errorMessage = error.toString()
                            }
                        }

                        Mixpanel.track('Operation: Google Maps failed to find post code', {
                            'userInput': customer.postCode,
                            'googleMapsError': errorMessage,
                            'page': 'Cart'
                        })

                        resolve({ postCodeError: 'We didn\'t find this post code ' + customer.postCode + ' in our supported delivery regions. Please double check that the post code is correct.', isPostCodeSupported: false })
                    })
            } else {
                resolve({ postCodeError: 'We didn\'t find this post code ' + customer.postCode + ' in our supported delivery regions. Please double check that the post code is correct.', isPostCodeSupported: false })
            }
        })
    }

    const handleSubmit = async (e) => {
        setIsLoadingPopUpShown(true)
        e.preventDefault()

        // CHECK IF POST CODE IS IN SUPPORTED DELIVERY REGION
        const { postCodeError, isPostCodeSupported } = await checkPostCode()

        Mixpanel.track('Button Tapped: Purchase meal plan(s)', {
            'totalPriceInCents': getTotalPriceInCents().toString(),
            'page': 'Cart'
        })

        const totalPriceInCents = getTotalPriceInCents()
        const remodelledCartItems = getRemodelledCartItems()

        // CHECK IF CUSTOMER DETAILS ARE MISSING
        if (customerDetailsAreAdded && isPostCodeSupported) {
            setServerMessage('Processing payment...')

            // CREATE PAYMENT (CAN FAIL IF CARD IS INCORRECT)
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)
            })

            // CHECK IF PAYMENT WAS CREATED SUCCESSFULLY
            if (!error) {
                try {
                    const { id } = paymentMethod

                    // ATTEMPT PAYMENT
                    const response = await axios.post(prodApi + '/payment', {
                        customerFirstName: customer.firstName,
                        customerLastName: customer.lastName,
                        customerEmail: customer.email,
                        customerPhoneNumber: customer.phoneNumber,
                        customerStreetName: customer.streetName,
                        customerStreetNumber: customer.streetNumber,
                        customerPostCode: customer.postCode,
                        customerCity: customer.city,
                        customerCountry: customer.country,
                        customerAllergens: (customer.allergens == null ? '' : customer.allergens),
                        customerDeliveryNote: (customer.deliveryNote == null ? '' : customer.deliveryNote),
                        cartItems: remodelledCartItems,
                        deliveryDate: deliveryDate,
                        secondDeliveryDate: secondDeliveryDate,
                        amount: totalPriceInCents,
                        id: id
                    })

                    // PAYMENT SUCCESSFUL
                    if (response.data.success) {
                        Mixpanel.track('Operation: PAYMENT SUCCESSFUL', {
                            'serverMessage': response.data.message,
                            'orderNumber': response.data.orderNumber.toString(),
                            'page': 'Cart'
                        })

                        setServerMessage(response.data.orderNumber.toString())

                        setSuccess(true)
                        setIsLoadingPopUpShown(false)
                        setIsPurchaseInfoPopUpShown(true)

                    // PAYMENT PROCESSING ERROR
                    } else {
                        const errorMessage = response.data.message.raw.message
                        
                        if (errorMessage != null && typeof errorMessage == 'string') {
                            Mixpanel.track('Operation: Failed to make payment', {
                                'error': errorMessage,
                                'page': 'Cart'
                            })
                            setServerMessage(errorMessage)
                        } else {
                            Mixpanel.track('Operation: Failed to make payment', {
                                'error': 'Unknown Error Occurred',
                                'page': 'Cart'
                            })
                            setServerMessage('Unknown Error Occurred')
                        }

                        setSuccess(false)
                        setIsLoadingPopUpShown(false)
                        setIsPurchaseInfoPopUpShown(true)
                    }
                // PAYMENT PROCESSING ERROR
                } catch (error) {
                    Mixpanel.track('Operation: Failed to make payment', {
                        'error': 'Unknown Error Occurred',
                        'page': 'Cart'
                    })

                    setServerMessage('Unknown Error Occurred')
                    setSuccess(false)
                    setIsLoadingPopUpShown(false)
                    setIsPurchaseInfoPopUpShown(true)
                }
            // CARD DETAILS ERROR
            } else {
                var errorMessage = 'Unknown Error Occurred'

                if (error != null) {
                    if (error.message != null) {
                        errorMessage = error.message
                    }
                }

                Mixpanel.track('Operation: Failed to make payment', {
                    'error': errorMessage,
                    'page': 'Cart'
                })

                setServerMessage(errorMessage)
                setSuccess(false)
                setIsLoadingPopUpShown(false)
                setIsPurchaseInfoPopUpShown(true)
            }
        // CUSTOMER DETAILS OR POST CODE ERROR
        } else {
            var validationErrorMessage = postCodeError ?? 'One or more mandatory fields is empty or the emails do not match. Please double check the details you have entered.'

            Mixpanel.track('Operation: Failed to make payment', {
                'error': validationErrorMessage,
                'page': 'Cart'
            })

            setTimeout(function() {
                setServerMessage(validationErrorMessage)
                setSuccess(false)
                setIsLoadingPopUpShown(false)
                setIsPurchaseInfoPopUpShown(true)
            }, 500)
            e.preventDefault()
        }
    }

    function hidePopUp() {
        setIsPurchaseInfoPopUpShown(false)
    }

    function clearCartAndLeavePage() {
        Mixpanel.track('Button Tapped: Clear All Items in Cart', {
            'page': 'Cart'
        })
        cartRemoveAllItems()
        setIsPurchaseInfoPopUpShown(false)
        window.scrollTo(0, 0)
        history.push('/')
    }

    return (
        <>
        {
            <form onSubmit={handleSubmit}>
                <fieldset className='FormGroup'>
                    <div className='FormRow'>
                        <CardElement options={CARD_OPTIONS} />
                    </div>
                </fieldset>
                {/* <a className='stripe-link' href='https://stripe.com' target='_blank' rel='noreferrer'>
                <div className='stripe-div'>
                    <p>Powered by </p>
                    <img className='stripe-logo' src='/images/stripe-logo.png'></img>
                    <p>payments.</p>
                </div>
                </a> */}
                <button className='payment-button'><i className="fa fa-money-bill-wave"></i>Purchase meal plan(s) for £ {cartTotal}</button>
                <br/><br/><br/>
                <LoadingPopUp isLoadingPopUpShown={isLoadingPopUpShown} />
                <PurchaseInfoPopUp 
                isPurchaseInfoPopUpShown={isPurchaseInfoPopUpShown} 
                serverMessage={serverMessage}
                success={success}
                handleOkayButton={hidePopUp}
                handleSuccessButton={clearCartAndLeavePage} />
            </form>
        }
        </>
    )
}
  
const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    cartRemoveAllItems: () => dispatch(cartRemoveAllItems())
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);