import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel.jsx';
import { mealPlanItemsData } from '../../components/common/MealPlans/data';
import MessengerCustomerChat from 'react-messenger-customer-chat/lib/MessengerCustomerChat.js';
import ButtonCartCount from '../../components/common/ButtonCartCount';
import PostCodePopUp from '../../components/common/PostCodePopUp';
import MealPlans from '../../components/common/MealPlans';
import Footer from '../../components/common/Footer';
import Banner from '../../components/Home/Banner';
import AboutUs from '../AboutUs';
import './styles.css';

const Home = () => {
  const [postCodePopUp, setPostCodePopUp] = useState(false);

  const history = useHistory();
  const mealPlansRef = useRef();
  const bannerRef = useRef();

  const scrollMealPlansIntoView = () => {
    Mixpanel.track('Button Tapped: View All Meal Plans', {
      'page': 'HomePage'
    })
    mealPlansRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollMealPlansIntoViewFromPostCodePopup = () => {
    Mixpanel.track('Button Tapped: View our meal plans', {
      'page': 'PostCodePopUp'
    })
    setPostCodePopUp(false);
    mealPlansRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  
  const handleOpenCart = () => {
    Mixpanel.track('Button Tapped: Cart', {
      'page': 'HomePage'
    })
    bannerRef.current.scrollIntoView();
    history.push('/cart');
  }

  function handleOpenMealPlan(mealPlanItem) {
    Mixpanel.track('Button Tapped: Add Me (on meal plan)', {
      'name': mealPlanItem.name,
      'page': 'HomePage'
    })
    window.scrollTo(0, 0)
    history.push('/meal-plan', [mealPlanItem]);
  }

  function openPostCodePopUp() {
    Mixpanel.track('Button Tapped: Check if we deliver to you', {
      'page': 'HomePage'
    })
    setPostCodePopUp(true);
  }

  function closePostCodePopUp() {
    Mixpanel.track('Button Tapped: Close', {
      'page': 'PostCodePopUp'
    })
    setPostCodePopUp(false);
  }

  window.onbeforeunload = function() {
    if (window.location.href.includes('meal-plan') || window.location.href.includes('cart')) {
      return "Are you sure? You have unsaved changes!";
    } else {
      return null;
    }
  };

  return (
    <div>
      <MessengerCustomerChat
      pageId='106384542128247'
      appId='557417392694422'
      version='14.0'
      language='en_GB'
      />
      <div className='header-background'></div>
      <button className='check-delivery-button' onClick={openPostCodePopUp}><i class="fa-solid fa-box-open"></i> Check if we deliver to you</button>
      <Banner handleScrollMealPlans={scrollMealPlansIntoView} ref={bannerRef} />
      <AboutUs />
      <MealPlans list={mealPlanItemsData} handleOpenMealPlan={handleOpenMealPlan} ref={mealPlansRef} />
      <Footer />
      <ButtonCartCount handleOpenCart={handleOpenCart} />
      <PostCodePopUp 
      closePostCodePopUp={closePostCodePopUp} 
      viewMealPlans={scrollMealPlansIntoViewFromPostCodePopup} 
      postCodePopUp={postCodePopUp} />
    </div>
  );
};

export default Home;
