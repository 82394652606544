import { CartActionTypes } from './cart.actionTypes';
import { addItemToCart, removeAllCartItems, removeItemFromCart } from './cart.utils';

const INITIAL_STATE = {
  cartItems: [],
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.CART_ADD_ITEM:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload)
      };
    case CartActionTypes.CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload)
      };
    case CartActionTypes.CART_REMOVE_ALL_ITEMS:
      return {
        ...state,
        cartItems: removeAllCartItems(state.cartItems)
      };
    default:
      return state;
  }
};

export default cartReducer;
