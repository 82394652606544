export const mealPlanItemsData = [
    {
        id: 801,
        type: 'meal-plan',
        name: 'Low Calorie Plan',
        info: ['1300 Calories'],
        minCalories: 1300,
        maxCalories: 1300,
        img: '/images/meal-plans/low-calorie.jpg',
        mealIDs: [101, 102, 103, 104, 105, 201, 202, 203, 204, 205, 301, 302, 303, 304, 305],
        price: 74.99,
        priceInCents: 7499
    },
    {
        id: 802,
        type: 'meal-plan',
        name: 'Balanced Plan',
        info: ['1400 - 1800 Calories'],
        minCalories: 1400,
        maxCalories: 1800,
        img: '/images/meal-plans/mid-calorie.jpg',
        mealIDs: [111, 112, 113, 114, 115, 211, 212, 213, 214, 215, 311, 312, 313, 314, 315],
        price: 84.99,
        priceInCents: 8499
    },
    {
        id: 803,
        type: 'meal-plan',
        name: 'Muscle Gain Plan',
        info: ['1800 - 2500 Calories'],
        minCalories: 1800,
        maxCalories: 2500,
        img: '/images/meal-plans/high-calorie.jpg',
        mealIDs: [121, 122, 123, 124, 125, 221, 222, 223, 224, 225, 321, 322, 323, 324, 325],
        price: 99.99,
        priceInCents: 9999
    }
];