import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartReducer from './cart/cart.reducer';
import userReducer from './user/user.reducer';

const persistConfig = {
  key: 'quoality-guest',
  storage,
  whitelist: ['cart', 'user'],
};

const appReducer = combineReducers({
  cart: cartReducer,
  user: userReducer
});

export default persistReducer(persistConfig, appReducer);
