import MenuItem from './MenuItem';
import React from 'react';
import './styles.css';

const Menu = ({ openItemDetails, mealPlanItem, list }) => {

  function getBreakfastItems() {
    const breakfastItems = list.filter(item => item.category === 'breakfast')
    const breakfastItemsFromThisMealPlan = breakfastItems.filter(item => mealPlanItem.mealIDs.includes(item.id))
    return breakfastItemsFromThisMealPlan
  }

  function getLunchItems() {
    const lunchItems = list.filter(item => item.category === 'lunch')
    const lunchItemsFromThisMealPlan = lunchItems.filter(item => mealPlanItem.mealIDs.includes(item.id))
    return lunchItemsFromThisMealPlan
  }

  function getDinnerItems() {
    const dinnerItems = list.filter(item => item.category === 'dinner')
    const dinnerItemsFromThisMealPlan = dinnerItems.filter(item => mealPlanItem.mealIDs.includes(item.id))
    return dinnerItemsFromThisMealPlan
  }

  return (
    <main className='menu-main'>
      <div className='breakfast-div'>
        <h1 className='menu-meal-time-title'>Breakfast</h1>
        {getBreakfastItems().map((item) => (
          <MenuItem openItemDetails={openItemDetails} item={item} key={item.id} />
        ))}
      </div>

      <div className='lunch-div'>
        <h1 className='menu-meal-time-title'>Lunch</h1>
        {getLunchItems().map((item) => (
          <MenuItem openItemDetails={openItemDetails} item={item} key={item.id} />
        ))}
      </div>

      <div className='dinner-div'>
        <h1 className='menu-meal-time-title'>Dinner</h1>
        {getDinnerItems().map((item) => (
          <MenuItem openItemDetails={openItemDetails} item={item} key={item.id} />
        ))}
      </div>
    </main>
  )
};

export default Menu;