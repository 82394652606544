import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  cartAddItem,
  cartRemoveItem,
} from '../../../../redux/cart/cart.action';
import {
  selectCartItems,
  selectCartItemsCount,
} from '../../../../redux/cart/cart.selector';
import './styles.css';

const MenuItem = ({
  openItemDetails,
  item,
  cartCount,
  cartList,
  cartAddItem,
  cartRemoveItem,
}) => {
  const { id, img, mobileImg, name, options, info } = item;
  const [forceUpdateValue, setForceUpdate] = useState(0);

  function forceUpdate() {
    setForceUpdate(forceUpdateValue + 1);
  }

  const OptionList = () => {
    if (options != null) {
      return (
        <div className='option-list-div'>
          <select
            value={item.selectedOption === null ? '1' : String(item.selectedOption + 1)}
            onChange={handleChange}>
            {
              Object.entries(options.idsAndNames).map(([id, name]) => {
                return <option key={id} value={id}>{name}</option>
              })
            }
          </select>
        </div>
      )
    } else {
      return null;
    }
  }

  function handleChange(event) {
    item.selectedOption = event.target.value - 1
    item.calories = item.options.calories[item.selectedOption]
    forceUpdate()
  }

  const Calories = () => {
    if (item.calories != null) {
      return (
        <div className='item-calories-div'>
          <p className='item-calories'>
            <b>{ item.calories === 0 ? '-' : item.calories }</b> Calories
          </p>
        </div>
      )
    } else {
      return null
    }
  }

  const DayOfWeek = () => {
    const lastDigitOfId = String(item.id).slice(-1)
    const dayOfWeek = getDayOfWeekFromId(lastDigitOfId)

    if (dayOfWeek != null) {
      return (
        <div className='day-of-week-div'>
          <p>{dayOfWeek}</p>
        </div>
      )
    } else {
      return null;
    }
  }

  function getDayOfWeekFromId(id) {
    switch (id) {
      case '1':
        return 'Monday'
      case '2':
        return 'Tuesday'
      case '3':
        return 'Wednesday'
      case '4':
        return 'Thursday'
      case '5':
        return 'Friday'
      case '6':
        return 'Saturday'
      case '7':
        return 'Sunday'
      default:
        return null;
    }
  }

  const Info = () => {
    if (info != null) {
      return (
        <p className='head_desc-info'>
          {
            info.map((infoElement, index) => {
              return <span key={index}>{infoElement}<br /></span>
            })
          }
        </p>
      )
    } else {
      return null;
    }
  }

  const getImgSrc = () => {
    var imageSrc = ""

    if (window.innerWidth <= 800) {
      if (options != null) {
        if (item.selectedOption != null) {
          imageSrc = options.mobileImages[item.selectedOption]
        } else {
          imageSrc = options.mobileImages[0]
        }
      } else {
        imageSrc = mobileImg
      }
    } else {
      if (options != null) {
        if (item.selectedOption != null) {
          imageSrc = options.images[item.selectedOption]
        } else {
          imageSrc = options.images[0]
        }
      } else {
        imageSrc = img
      }
    }

    item.selectedImage = imageSrc
    return imageSrc
  }

  return (
    <div className='item'>
      <div onClick={() => openItemDetails(id)} className='item-image-div'>
        <img className='item-image' src={getImgSrc()} importance='high' alt='menu-item' />
        <i className="item-meal-plan-expand-icon fa fa-up-right-and-down-left-from-center"></i>
      </div>
      <div onClick={() => openItemDetails(id)} className='item-head_desc'>
        <p className='head_desc-name'>{name}</p>
        <Info />
      </div>
      <OptionList />
      <Calories />
      <DayOfWeek />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  cartCount: selectCartItemsCount,
  cartList: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item) => dispatch(cartAddItem(item)),
  cartRemoveItem: (item) => dispatch(cartRemoveItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
