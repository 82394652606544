import React from 'react';
import './styles.css';

const MealPlanItem = ({ item, handleOpenMealPlan }) => {
  const { id, img, name, price, info } = item;

  const Info = () => {
    if (info != null) {
      return (
        <p className='meal-plan-info'>
          {
            info.map((infoElement, index) => {
              return <span key={index}>{infoElement}<br/></span>
            })
          }
        </p>
      )
    } else {
      return null;
    }
  }

  return (
    <div className='item-meal-plan'>
      <img className='item-image' src={img} alt='food' />
      <div className='item-head_desc'>
        <p className='meal-plan-name'>{name}</p>
        <Info />
      </div>
      <div className='item-add-button-div'>
        <p className='item-meal-plan-price'>£{price}</p>
        <button className='meal-plan-button' onClick={() => handleOpenMealPlan(id)}>
          Add Me<i className="fa-solid fa-utensils"></i>
        </button>
      </div>
    </div>
  );
};

export default MealPlanItem;
