import React from 'react';
import './about-us-styles.css';

const AboutUs = () => {
    return (
    <>
        <div className='what-we-serve-div-about-us'>
            <h1>Why should you choose us?</h1>
        </div>
        
        <div className='what-we-serve-images-div-about-us'>
            <div>
                <img src='/images/about-us/easy-to-order.png' alt='easy-to-order-img'></img>
                <p>Easy To Order</p>
                <span className='small'>Personlized Menus</span>
            </div>
            <div>
                <img src='/images/about-us/fastest-delivery.png' alt='fast-delivery-img'></img>
                <p>Fast Delivery</p>
                <span className='small'>Every Tuesday and Sunday</span>
            </div>
            
            <div>
                <img src='/images/about-us/best-quality.png' alt='best-quality-img'></img>
                <p>Premium Quality</p>
                <span className='small'>Fresh and Delicious</span>
            </div>
        </div>

        <div className='delivery-info-div'>
            <div className='info-icon'>
                <i className="fa fa-info"></i>
            </div>
            <span className='delivery-info'>
                We will deliver your Monday and Tuesday meals on Sunday evening,<br/>
                and your Wednesday, Thursday and Friday meals on Tuesday evening.<br/>
                <span className='delivery-weekend-soon'>(Soon available option for extra meals for the weekend!)</span>
            </span>
        </div>

        <div className='main-div-about-us'>
            <div className='about-us-item'>
                <div className='left-item'> 
                    <p>You can change your meals to your preference and taste.</p>
                    <p className='small-left'>Choose between <b>low</b>, <b>balanced</b> and <b>high calorie menus.</b></p>
                    <p className='small-left'>We believe every meal you like has a healthy way to be prepared!</p>
                </div>
                <div className='image-with-text'>
                    <img className='right-item' src='/images/about-us/change-meals-to-preference.gif' alt='change-meals-img'></img>
                    <div className='text-under-image'>
                        <p className='text-image-left'>Caesar with chicken</p>
                        <p className='text-image'>/</p>
                        <p className='text-image-right'>Caesar with shripms</p>
                    </div>
                </div>
            </div>

            <div className='mobile-div'>
                <span className='mobile-small'>Choose between <b>low</b>, <b>balanced</b> and <b>high calorie menus.</b></span>
                <span className='mobile-small'>We believe every meal you like has a healthy way to be prepared!</span>
            </div>

            <div className='about-us-item'>
                <img className='left-item' src='/images/about-us/fresh-products.png' alt='fresh-products-img'></img>
                <div className='right-item'>
                    <p>We use local farm products and fresh ingriedients.</p>
                    <p className='small-right'>Our bread, pasta and noodles are <b>homemade</b>!</p>
                </div>
            </div>

            <div className='mobile-div'>
                <span className='mobile-small'>Our bread, pasta and noodles are <b>homemade</b>!</span>
            </div>

            <div className='about-us-item'>
                <div className='left-item'> 
                    <p>We are a new small local business with experienced chefs, ready to save your time and prepare healthy delicious meals.</p>
                    <p className='small-left'>Leave it all to us - we cook and deliver to your door, <br/>just <b>warm it up</b> and <b>enjoy</b>!</p>
                </div>
                <img className='right-item' src='/images/about-us/experienced-chefs.gif' alt='experienced-chefs-img'></img>
            </div>

            <div className='mobile-div'>
                <span className='mobile-small'>Leave it all to us - we cook and deliver to your door, just <b>warm it up</b> and <b>enjoy</b>!</span>
            </div>

            <div className='lets-get-started'>
                <h1>Let's get started!</h1>
            </div>
        </div>
    </>
    );
};

export default AboutUs;