import React from 'react';
import './styles.css';

const MenuItemPopUpDetails = ({ closeItemDetails, item }) => {
  const Calories = () => {
    if (item.calories != null) {
      return (
        <div className='pop-up-item-calories-div'>
          <p className='pop-up-item-calories'>
            <b>{ item.calories === 0 ? '-' : item.calories }</b> Calories
          </p>
        </div>
      )
    } else {
      return null
    }
  }

  const getImgSrc = () => {
    if (item.selectedImage != null) {
      return item.selectedImage
    } else {
      if (window.innerWidth <= 800) {
        return item.mobileImg
      } else {
        return item.img
      }
    }
  }

  return (
    item != null
    ?
    <div className='modal'>
      <div className='modal-content'>
        <button className='modal-close-button' onClick={closeItemDetails}>
          <i className="fa-solid fa-xmark"></i> Close
        </button>
        <img className='menu-pop-up-img' src={getImgSrc()} alt='meal-img'></img>
        <div className='meal-content-div'>
          <h1 className='meal-name'>{item.name}</h1>
          <p className='meal-info'>{item.info}</p>
          <Calories />
        </div>
      </div>
    </div>
    :
    null
  );
};

export default MenuItemPopUpDetails;
