import React from 'react';
import Logo from '../Logo';
import { useHistory } from 'react-router';
import { Mixpanel } from '../../../components/Mixpanel/Mixpanel';
import './styles.css';

const Footer = () => {
  const history = useHistory()

  function openTermsAndConditions() {
    Mixpanel.track('Button Tapped: Open Terms and Conditions')
    window.scrollTo(0, 0)
    history.push('/terms-and-conditions')
  }

  function openPrivacyPolicy() {
    Mixpanel.track('Button Tapped: Open Privacy Policy')
    window.scrollTo(0, 0)
    history.push('/privacy-policy')
  }

  function openCookiePolicy() {
    Mixpanel.track('Button Tapped: Open Cookie Policy')
    window.scrollTo(0, 0)
    history.push('/cookie-policy')
  }

  return (
    <footer>
      <div className='recyclable-div'>
          <p>We use only recyclable trays.</p>
          <img src='/images/about-us/recyclable-trays.png' alt='recyclable-tray-img'></img>
      </div>
      <div className='mokka-footer'>
        <p>
        <span className='item-thank-you'>Thank You</span> <span className='item-heart'>❤️</span> for supporting our new business!
        </p>
        <Logo />
      </div>
      <div className='credits-footer'>
        <a href='https://storyset.com/work' target='_blank' rel='noreferrer'>Illustrations by Storyset</a>
        <button className='button-link' onClick={openTermsAndConditions}>Terms and Conditions</button>
        <button className='button-link' onClick={openPrivacyPolicy}>Privacy Policy</button>
        <button className='button-link' onClick={openCookiePolicy}>Cookie Policy</button>
      </div>
      <p className='footer-version'>Ver.: 10.5.9</p>
    </footer>
  );
};

export default Footer;
