import React from 'react'
import Geocode from 'react-geocode'
import { useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { addUser } from '../../../redux/user/user.action'
import { selectUserItem } from '../../../redux/user/user.selector'
import { Mixpanel } from '../../Mixpanel/Mixpanel'
import './styles.css'

const PostCodePopUp = ({ closePostCodePopUp, viewMealPlans, postCodePopUp, userItem, addUser }) => {
    const [postCode, setPostCode] = useState(userItem.user.postCode);
    const [erroredPostCode, setErroredPostCode] = useState(null);

    Geocode.setApiKey('AIzaSyBd5yIaLFuCgaTrOyjqtE_pPm4xZW9bq00');
    Geocode.setRegion('UK');

    function handleInputChange(e) {
        if (e.target.value != null && e.target.value.length >= 4) {
            Geocode.fromAddress(e.target.value).then(
                (response) => {
                    const isInLondon = (response.results[0].address_components.find((item) => item.short_name.includes('London')) != null)
                    const isInEssex = (response.results[0].address_components.find((item) => item.short_name === 'Essex') != null)

                    // For debugging:
                    // console.clear()
                    // console.log(response.results[0].address_components)
                    // console.log('isInLondon:' + isInLondon)
                    // console.log('isInEssex:' + isInEssex)

                    Mixpanel.track('Operation: Google Maps successfully found post code', {
                        'userInput': e.target.value,
                        'page': 'PostCodePopUp'
                    })

                    if (isInEssex) {
                        const isInEpping = (response.results[0].address_components.find((item) => item.short_name.includes('Epping')) != null)
                        const isInLoughton = (response.results[0].address_components.find((item) => item.short_name.includes('Loughton')) != null)
                        const isInWaltham = (response.results[0].address_components.find((item) => item.short_name.includes('Waltham Abbey')) != null)

                        if (isInEpping || isInLoughton || isInWaltham) {
                            Mixpanel.track('Operation: Post code is in Essex', {
                                'userInput': e.target.value,
                                'page': 'PostCodePopUp'
                            })
                            setPostCode(e.target.value)
                            savePostCodeInCookies(e.target.value)
                        } else {
                            Mixpanel.track('Operation: Post code is in unsupported delivery region', {
                                'userInput': e.target.value,
                                'page': 'PostCodePopUp'
                            })
                            setPostCode('unsupported')
                            setErroredPostCode(e.target.value)
                        }
                    } else {
                        if (isInLondon && 
                            (e.target.value[0].toUpperCase() === 'N' 
                            || e.target.value[0].toUpperCase() === 'E'
                            || (e.target.value[0].toUpperCase() === 'I' && e.target.value[1].toUpperCase() === 'G')
                            || (e.target.value[0].toUpperCase() === 'R' && e.target.value[1].toUpperCase() === 'M'))) {
                            Mixpanel.track('Operation: Post code is in London', {
                                'userInput': e.target.value,
                                'page': 'PostCodePopUp'
                            })
                            setPostCode(e.target.value)
                            savePostCodeInCookies(e.target.value)
                        } else {
                            Mixpanel.track('Operation: Post code is in unsupported delivery region', {
                                'userInput': e.target.value,
                                'page': 'PostCodePopUp'
                            })
                            setPostCode('unsupported')
                            setErroredPostCode(e.target.value)
                        }
                    }
                },
                (error) => {
                    var errorMessage = ''

                    if (error != null) {
                        if (error.toString() != null) {
                            errorMessage = error.toString()
                        }
                    }

                    Mixpanel.track('Operation: Google Maps failed to find post code', {
                        'userInput': e.target.value,
                        'googleMapsError': errorMessage,
                        'page': 'PostCodePopUp'
                    })

                    setPostCode('error')
                    setErroredPostCode(e.target.value)
                })
        } else {
            if (e.target.value === '') {
                setPostCode(null)
            }
            return
        }
    }

    function savePostCodeInCookies(postCodeInput) {
        if (postCodeInput != null) {
            var newUser = userItem.user
            newUser.postCode = postCodeInput.toUpperCase()
            addUser({ user: newUser })
        }
    }

    function sendUsAnEmail() {
        var codeForEmail = ''

        if (erroredPostCode != null) {
            codeForEmail = erroredPostCode
        }

        Mixpanel.track('Button Tapped: Send email (for unsupported post code)', {
            'userInput': codeForEmail,
            'page': 'PostCodePopUp'
        })

        const subject = 'When will delivery be available for post code ' + codeForEmail.toUpperCase() + '?'
        window.open('mailto:orders@mokkafood.co.uk?cc=developers@mokkafood.co.uk&subject=' + subject)
    }

    function closePopUp() {
        setPostCode(null);
        closePostCodePopUp();
    }

    const ResponseMessage = () => {
        if (postCode == null) {
            return null;
        } else if (postCode === 'unsupported') {
            return (
                <div className='pop-up-response-div'>
                    <p className='post-code-sorry'>
                        Oops! Unfortunately, we don't deliver here yet.
                        You could send us an e-mail to request delivery for your location at
                        orders@mokkafood.co.uk!
                    </p>
                    <button className='post-code-sorry-button' onClick={sendUsAnEmail}>
                        <i className='post-code-icon fa-solid fa-envelope'></i> Send us an e-mail
                    </button>
                </div>
            )
        } else if (postCode === 'error') {
            return (
                <div className='pop-up-response-div'>
                    <p className='post-code-sorry'>
                        Oops! Please double-check that the post code is correct.
                    </p>
                    <button className='post-code-sorry-button' onClick={sendUsAnEmail}>
                        <i className='post-code-icon fa-solid fa-envelope'></i> Send us an e-mail
                    </button>
                </div>
            )
        } else {
            return (
                <div className='pop-up-response-div'>
                    <p className='post-code-success'>
                        Good news!<br/>
                        We deliver to this post code!
                    </p>
                    <button className='post-code-success-button' onClick={viewMealPlans}>
                        <i className="post-code-icon fa-solid fa-utensils"></i> View our meal plans
                    </button>
                </div>
            )
        }
    }

    return (
    postCodePopUp === true
    ?
    <div className='modal-post-code'>
        <div className='modal-content-post-code'>
            <button className='modal-close-button-post-code' onClick={closePopUp}>
                <i className="fas fa-times"></i>
            </button>
            <p className='post-code-question'>
                Could you please enter your post code so we can check if we deliver to you?
            </p>
            <form className='post-code-form'>
                <input className='post-code-text-field' type='text' onChange={handleInputChange} placeholder={ 
                    (postCode === null || postCode === 'error') ? 'Enter Post Code Here' : postCode 
                }></input>
            </form>
            <ResponseMessage />
        </div>
    </div>
    :
    null
    );
};

const mapStateToProps = createStructuredSelector({
    userItem: selectUserItem
});

const mapDispatchToProps = (dispatch) => ({
    addUser: (item) => dispatch(addUser(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCodePopUp);