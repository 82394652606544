import { UserActionTypes } from './user.actionTypes';
import { addUser } from './user.utils';

const INITIAL_STATE = {
  user: {
    firstName: null,
    lastName: null,
    email: null,
    confirmedEmail: null,
    phoneNumber: null,
    streetName: null,
    streetNumber: null,
    postCode: null,
    allergens: null,
    deliveryNote: null,
    city: 'London',
    country: 'UK'
  },
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.ADD_USER:
      return addUser(state.user, action.payload)
    default:
      return state;
  }
};

export default userReducer;
