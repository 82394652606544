import React from 'react'
import { useHistory } from 'react-router';
import Footer from './../../components/common/Footer';
import './styles.css';

const CookiePolicy = () => {
    const history = useHistory()
        
    return (
        <div className='privacy-policy-div'>
            <button className='back-button' onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left"></i>Go Back
            </button>
            <h1>Cookie Policy</h1>

            <h3>What Are Cookies</h3>
            <p>It is common practice with almost all professional websites to use cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this will certainly break certain elements of this website's functionality.</p>
            <p>For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>

            <h3>How We Use Cookies</h3>
            <p>We use cookies for a variety of reasons detailed below. Unfortunately, in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them, or in case they are used to provide a service that you use.</p>

            <h3>Disabling Cookies</h3>
            <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser's Help page for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will result in also disabling certain functionality and features of this site. Therefore, it is recommended that you do not disable cookies.</p>

            <h3>Third Party Cookies</h3>
            <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
            <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solutions on the web. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official Google Analytics page.</p>
            
            <h3>We use the following cookies:</h3>
            <p>Customer Information cookies - These cookies record the following details you enter into our payment form upon ordering: first name, last name, e-mail address, phone number, physical address, allergens, delivery notes. We use this information purely for your convenience in case you decide to revisit your order later or make a second order in the future and not have to type in all your details again. We DO NOT save your debit or credit card details.</p>
            <p>Personalisation cookies - These allow us to remember your meal plan customizations in order for you to easily complete your order with us.</p>
            <p>Essential cookies - These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to navigate our website, and use its features. When you return to the site, they enable us to remember the products that you’ve looked at or added to your basket.</p>
            <p>Remarketing cookies - These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.</p>

            <br />
            <p>By continuing to browse our website or use the app, you provide consent to us storing these cookies on your device and using them if you visit our site again. You can delete cookies at any time in your device browser. The method of deletion will vary so please check the help section on your device browser for more information. If you delete or block cookies it may affect the way our website works for you.</p>

            <h3>More Information</h3>
            <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren’t sure whether you need or not it’s usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. This Cookies Policy was created with the help of the Generator of GDPR Cookies Policy Template and the GDPR Cookies Policy Template.</p> 

            <p>However, if you are still looking for more information, you can contact us via email at: developers@mokkafood.co.uk</p>

            <br />
            <br />
            <br />
            <br />
            <br />
            <Footer />
        </div>
    )
}

export default CookiePolicy